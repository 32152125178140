import React from 'react'
import { NftSVG } from './NftSVG'
import { EventSVG } from './EventSVG'
import { PPaySVG } from './PPaySVG'
import { Fade } from 'react-awesome-reveal'

const YellowCards = () => {
    return (
        <div className="bg-bgyellow py-14 lg:py-28 relative -top-[8rem]">
            <div className="custom-container flex flex-col lg:flex-row lg:space-x-10 lg:space-y-0 space-x-0 space-y-10">
                <div className="flex flex-col w-full lg:w-1/3 space-y-4">
                    <Fade direction="up" triggerOnce delay={100}>
                        <NftSVG />
                        <h3 className="max-w-[70%]">It’s a turnkey solution</h3>
                        <p className="bodyMD">
                            Using Souvie, attendees simply scan a QR code with
                            their phone, and log in with their Gmail, Facebook,
                            or Twitter account. Once they've created a wallet,
                            they can claim their souvenir NFT with one click.
                        </p>
                    </Fade>
                </div>
                <div className="h-[1px] w-auto lg:w-[1px] lg:h-auto bg-black"></div>
                <div className="flex flex-col w-full lg:w-1/3 space-y-4">
                    <Fade direction="up" triggerOnce delay={200}>
                        <EventSVG />
                        <h3 className="max-w-[70%]">
                            It’s simple, and the first of its kind
                        </h3>
                        <p className="bodyMD">
                            A frictionless app experience, no blockchain
                            knowledge required.
                        </p>
                    </Fade>
                </div>
                <div className="h-[1px] w-auto lg:w-[1px] lg:h-auto bg-black"></div>
                <div className="flex flex-col w-full lg:w-1/3 space-y-4">
                    <Fade direction="up" triggerOnce delay={300}>
                        <PPaySVG />
                        <h3 className="max-w-[70%]">
                            Eco-friendly with low gas fees
                        </h3>
                        <p className="bodyMD">
                            Souvie was consciously built on Tezos, an
                            eco-friendly blockchain that every brand can feel
                            proud using.
                        </p>
                    </Fade>
                </div>
            </div>
        </div>
    )
}

export { YellowCards }
