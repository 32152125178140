import React from 'react'

const EventSVG = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="53"
            height="102"
            fill="none"
            viewBox="0 0 53 102"
        >
            <path
                fill="#131313"
                d="M42.456 71.25c-3.357 0-6.325 1.677-8.137 4.251a10.18 10.18 0 00-1.855 5.88c0 2.976 1.263 5.652 3.279 7.503a9.875 9.875 0 006.713 2.63c5.518 0 9.992-4.537 9.992-10.132 0-5.596-4.474-10.132-9.992-10.132zm4.24 10.088c-2.332 0-4.222 1.913-4.222 4.281 0-2.365-1.887-4.281-4.222-4.281 2.332 0 4.222-1.914 4.222-4.282 0 2.366 1.887 4.282 4.223 4.282zM23.27.174h-3.192v4.585h3.192V.174zM.293 72.166l-.243.245L14.258 86.82l.243-.246L.293 72.166zM14.5 86.576l-.243.246 14.969 15.179.242-.246L14.5 86.576z"
            ></path>
            <path
                fill="#131313"
                d="M.343 46.918H0V72.29h.343V46.918zM21.16 50.486h-.342V43.05c0-3.816-2.934-7.03-6.681-7.317a7.364 7.364 0 00-.556-.021c-2.865 0-5.465 1.72-6.623 4.381l-.313-.141c1.213-2.787 3.936-4.59 6.939-4.59.194 0 .39.007.58.021 3.923.301 6.999 3.668 6.999 7.664v7.435l-.003.003z"
            ></path>
            <path
                fill="#131313"
                d="M27.924 50.486h-.343v-9.669c0-2.19-.92-4.284-2.527-5.748a7.55 7.55 0 00-5.088-1.977c-2.167 0-4.241.941-5.688 2.584l-.255-.232a7.938 7.938 0 015.942-2.699c1.965 0 3.855.735 5.317 2.064a8.138 8.138 0 012.642 6.008v9.669z"
            ></path>
            <path
                fill="#131313"
                d="M34.002 50.877h-.343V13.735c0-2.326-1.867-4.217-4.158-4.217-2.292 0-4.161 1.89-4.161 4.217v21.204h-.343V13.735c0-2.516 2.02-4.565 4.504-4.565 2.483 0 4.5 2.049 4.5 4.565v37.142zM14.55 60.047h-.171c-4.159 0-7.54-3.43-7.54-7.648v-6.227h.342v6.227c0 3.967 3.138 7.206 7.026 7.299V47.212c0-3.876-3.11-7.03-6.932-7.03-.167 0-.317.005-.464.017-3.628.243-6.468 3.324-6.468 7.013H0c0-3.872 2.982-7.106 6.788-7.361.154-.012.311-.016.487-.016 4.012 0 7.275 3.308 7.275 7.377v12.835z"
            ></path>
            <path
                fill="#131313"
                d="M26.329 60.048H21.74c-4.02 0-7.328-3.186-7.534-7.255-.007-.13-.009-.26-.009-.394h.343c0 .128.002.25.01.376.193 3.883 3.353 6.925 7.19 6.925h4.588v.348zM42.456 91.687c-2.532 0-4.956-.95-6.828-2.673a10.377 10.377 0 01-3.336-7.632c0-2.162.654-4.231 1.888-5.983 1.905-2.708 5-4.323 8.276-4.323 5.605 0 10.163 4.622 10.163 10.306 0 5.683-4.558 10.305-10.163 10.305zm0-20.263c-3.167 0-6.156 1.561-7.997 4.18a9.974 9.974 0 00-1.824 5.778c0 2.803 1.175 5.49 3.222 7.372a9.722 9.722 0 006.599 2.586c5.415 0 9.82-4.467 9.82-9.958 0-5.491-4.405-9.958-9.82-9.958zM40.057 66.442l-.295-.178a10.414 10.414 0 001.432-5.285c0-4.604-3.047-8.69-7.412-9.937a10.101 10.101 0 00-2.764-.385h-16.64v-.347H31.02c.97 0 1.93.134 2.856.398 4.513 1.288 7.664 5.512 7.664 10.271 0 1.93-.512 3.818-1.483 5.461v.002z"
            ></path>
            <path
                fill="#131313"
                d="M39.764 66.267l-5.59 9.15.291.182 5.59-9.148-.291-.183zM14.55 82.846h-.343c0-9.252 4.495-17.894 12.026-23.116l.194.287c-7.435 5.158-11.874 13.693-11.874 22.829h-.003zM14.207 86.7v-3.854h.343v3.85l-.343.003zM35.863 88.764l-.242.246L48.433 102l.243-.245-12.813-12.992zM35.985 9.518h-12.97v-4.76h.343V9.17h12.284V4.76h.343v4.759z"
            ></path>
            <path
                fill="#131313"
                d="M39.095 4.933H19.906V0h19.189v4.933zm-18.846-.348h18.503V.348H20.25v4.237zM10.232 17.98H3.368v.347h6.864v-.348z"
            ></path>
            <path
                fill="#131313"
                d="M6.971 14.673h-.343v6.958h.343v-6.958z"
            ></path>
        </svg>
    )
}

export { EventSVG }
