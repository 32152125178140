import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import { Head } from '../components/MetaInfo'
import { Hero } from '../components/Hero'
import { HeroVideo } from '../components/HeroVideo'
import { CallToAction } from '../components/CallToAction'
import { VideoBlocks } from '../components/VideoBlocks'
import { YellowCards } from '../components/YellowCards'
import { Stats } from '../components/Stats'

const App = () => {
    return (
        <>
            <Head
                title="Souvie"
                description="Meet Souvie: The first ever NFT gifting app, created to revolutionize how patrons experience live events. Souvie is a customizable, turnkey souvenir solution for brands, powered by blockchain technology."
            />
            <main>
                <Hero>
                    <StaticImage
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                        layout="constrained"
                        alt="Hero image"
                        placeholder="blurred"
                        transformOptions="fit"
                        src={'../images/hero_image.png'}
                        formats={['auto', 'webp', 'avif']}
                        className="static_override"
                    />
                </Hero>
                <HeroVideo video_url="https://player.vimeo.com/video/779719699?h=2e931cd712&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" />
                <CallToAction title="Meet Souvie: The first ever NFT gifting app, created to revolutionize how patrons experience live events">
                    <p className="text-black">
                        Until now, every brand that has hosted an in-person
                        event has run into the same dilemma: 'How can we provide
                        attendees with a meaningful souvenir of their
                        experience, that won’t end up in the trash?'
                        <br />
                        <br />
                        Instead of defaulting to tired clichés like cheap
                        tchotchkes and branded pens, we decided to harness the
                        hype and public curiosity around NFTs to build Souvie –
                        a customizable, turnkey souvenir solution for brands,
                        powered by blockchain technology.
                    </p>
                </CallToAction>
                <YellowCards />
                <VideoBlocks />
                <div className="pb-28 pt-14 lg:pb-[200px] lg:pt-20 bg-xblack relative -top-[8rem]">
                    <Stats color="text-primary" />
                </div>
            </main>
        </>
    )
}

export default App
