import React from 'react'

const PPaySVG = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="118"
            height="102"
            fill="none"
            viewBox="0 0 118 102"
        >
            <path
                fill="#131313"
                d="M114.112 101.801c4.605-.625 4.984-3.762.535-8.87-.558-.642-1.184-2.162-1.864-4.255-2.064-6.351-4.637-18.005-7.421-26.587 1.193 3.953 1.759 8.997 1.398 14.893-.131 2.14-.401 4.095-.811 5.876-.504 2.202-1.213 4.134-2.115 5.818 1.839 2.843 3.991 5.561 5.442 7.313 1.028 1.234 2.602 1.959 4.261 1.959h.575v3.853zM96.556 37.467c-4.392 0-7.95 3.37-7.95 7.526 0 4.154 3.507 7.48 7.856 7.525.04 0 .08 0 .117.003 4.38-.01 7.927-3.379 7.927-7.528 0-4.15-3.558-7.526-7.95-7.526zM.213 97.947h17.608c.042-2.721 2.038-4.308 6.623-5.106 4.29-.743 6.069-1.107 11.553-6.119.444-.407.917-.846 1.415-1.317.64-.607 1.4-1.307 2.257-2.08a4174.188 4174.188 0 01-19.3 2.969c-.92.137-1.771.267-2.551.382-2.57.38-4.338.628-5.007.7-.526.055-1.016.1-1.469.128-3.945.253-5.274-.636-6.207-5.055-.886-4.201-1.981-7.1-4.922-7.865v23.363zM39.897 56.463c-.185.916-.45 1.805-.789 2.66h3.937c.276-.863.492-1.752.64-2.66h-3.788zM31.227 39.916c0-.283.028-.558.082-.824a17.64 17.64 0 00-3.142-1.28 7.07 7.07 0 00-.316 2.104v.016c1.38.45 2.67 1.089 3.834 1.878a4.181 4.181 0 01-.458-1.894zM35.858 42.37c-.06 0-.117-.005-.176-.007a16.196 16.196 0 011.494 1.767c1.258-.35 2.294-1.19 2.86-2.29a20.02 20.02 0 00-1.585-1.846 2.382 2.382 0 01-.757 1.657 2.726 2.726 0 01-1.839.72h.003z"
            ></path>
            <path
                fill="#131313"
                d="M113.537 97.745H.213v.404h113.324v-.404zM42.635 56.576c-11.533 0-20.995-8.536-21.535-19.432l.427-.02c.529 10.682 9.802 19.05 21.108 19.05v.405-.003zM21.675 33.603l-.425-.051c.555-4.107 2.4-7.911 5.329-11.005 3.125-3.297 7.187-5.504 11.744-6.377l.085.396c-4.466.857-8.445 3.02-11.51 6.254-2.872 3.031-4.677 6.76-5.22 10.783h-.003z"
            ></path>
            <path
                fill="#131313"
                d="M80.841 56.374h-.427V20.408C80.414 9.377 70.936.404 59.283.404c-10.01 0-18.72 6.731-20.71 16.003l-.418-.081C40.185 6.866 49.07 0 59.283 0c11.886 0 21.558 9.156 21.558 20.408v35.966zM35.867 44.51c-2.676 0-4.853-2.06-4.853-4.594 0-.29.028-.58.085-.862l.418.075a4.05 4.05 0 00-.08.787c0 2.31 1.988 4.19 4.427 4.19.424 0 .845-.056 1.25-.17 1.19-.331 2.183-1.129 2.729-2.185.29-.566.444-1.199.444-1.835 0-2.309-1.984-4.187-4.423-4.187-.817 0-1.617.213-2.314.617l-.222-.345a5.063 5.063 0 012.536-.676c2.675 0 4.85 2.058 4.85 4.591 0 .698-.168 1.393-.49 2.013-.597 1.159-1.688 2.032-2.991 2.395a5.076 5.076 0 01-1.37.186h.004z"
            ></path>
            <path
                fill="#131313"
                d="M35.866 47.706c-4.537 0-8.228-3.495-8.228-7.79 0-.74.108-1.468.324-2.16l.41.112a6.851 6.851 0 00-.307 2.048c0 4.072 3.5 7.386 7.801 7.386 1 0 1.967-.175 2.88-.523a7.803 7.803 0 003.015-2.032c1.227-1.341 1.904-3.058 1.904-4.83 0-4.072-3.498-7.386-7.799-7.386-2.16 0-4.167.816-5.652 2.298l-.31-.278c1.565-1.562 3.683-2.422 5.96-2.422 4.537 0 8.225 3.495 8.225 7.79 0 1.873-.711 3.68-2.006 5.095a8.235 8.235 0 01-3.18 2.145 8.51 8.51 0 01-3.04.55l.003-.003z"
            ></path>
            <path
                fill="#131313"
                d="M14.943 68.047C9.128 65.19 5.52 59.57 5.52 53.375c0-4.136 1.622-8.094 4.568-11.144 2.926-3.029 6.906-4.912 11.206-5.3a17.78 17.78 0 011.677-.076c1.796 0 3.566.256 5.263.763 1.101.328 2.171.765 3.182 1.296a17.297 17.297 0 015.937 5.1l-.353.23a16.859 16.859 0 00-5.795-4.977 17.407 17.407 0 00-3.102-1.264 17.956 17.956 0 00-6.769-.67c-8.772.791-15.387 7.69-15.387 16.04 0 6.04 3.524 11.527 9.197 14.31l-.197.358-.003.006z"
            ></path>
            <path
                fill="#131313"
                d="M22.97 69.895c-2.787 0-5.562-.638-8.024-1.846l.196-.358a17.623 17.623 0 007.83 1.803c8.203 0 15.242-5.532 16.737-13.155l.418.073c-1.531 7.814-8.747 13.483-17.157 13.483z"
            ></path>
            <path
                fill="#131313"
                d="M22.97 73.428a22.18 22.18 0 01-7.233-1.202c-8.345-2.87-13.95-10.447-13.95-18.85 0-10.477 8.636-19.261 19.663-20.002.487-.035 1-.051 1.52-.051 2.462 0 4.873.396 7.161 1.175a21.96 21.96 0 013.416 1.5 21.042 21.042 0 016.66 5.724l-.347.234a20.645 20.645 0 00-6.53-5.61 21.334 21.334 0 00-3.347-1.468 21.69 21.69 0 00-7.019-1.154 20.4 20.4 0 00-1.488.051C10.67 34.5 2.209 43.11 2.209 53.373c0 8.236 5.493 15.66 13.67 18.47a21.722 21.722 0 007.085 1.178c4.995 0 9.823-1.706 13.594-4.8 3.732-3.063 6.191-7.282 6.928-11.88l.422.06c-.752 4.694-3.262 9-7.07 12.125-3.849 3.158-8.776 4.899-13.874 4.899l.006.003zM10.258 87.743c-3.322 0-4.486-1.255-5.331-5.254C3.726 76.79 2.21 75.314.159 74.78l.114-.39c2.98.775 4.153 3.642 5.075 8.021.942 4.454 2.32 5.128 5.983 4.893a38.51 38.51 0 001.46-.126l.046.401c-.459.046-.99.1-1.477.127-.39.024-.758.037-1.1.037h-.002z"
            ></path>
            <path
                fill="#131313"
                d="M12.837 87.58l-.048-.402c.66-.073 2.388-.313 4.998-.7l2.55-.384c4.372-.657 10.685-1.627 19.3-2.969l.07.399a4261.689 4261.689 0 01-19.302 2.97l-2.55.382c-2.616.388-4.35.63-5.015.7l-.003.003z"
            ></path>
            <path
                fill="#131313"
                d="M18.031 98.04h-.426V86.675h.426v9.348c.826-1.7 2.878-2.773 6.373-3.382 4.196-.727 5.93-1.03 11.443-6.065.444-.407.913-.844 1.411-1.315.646-.612 1.406-1.312 2.26-2.083 3.7-3.347 9.866-8.682 17.83-15.429 3.84-3.255 9.293-3.934 13.895-1.732l.402.194-.194.36-.401-.193c-4.443-2.126-9.709-1.469-13.418 1.673-7.958 6.744-14.12 12.074-17.818 15.418-.85.768-1.61 1.469-2.254 2.078-.498.471-.973.913-1.417 1.32-5.608 5.122-7.506 5.45-11.665 6.173-4.417.768-6.407 2.282-6.45 4.91l-.002.07v.018h.005zM105.966 83.093l-.114-.054-16.842-8.065.194-.36 16.955 8.118-.193.36z"
            ></path>
            <path
                fill="#131313"
                d="M52.167 90.834l-12.612-7.337.225-.345 12.407 7.219c7.885-3.813 15.934-11.608 16.016-11.686l.308.282c-.083.081-8.252 7.992-16.236 11.819l-.105.05-.003-.002z"
            ></path>
            <path
                fill="#131313"
                d="M68.391 79.024l-.07-.399c7.818-1.231 14.211-2.244 17.203-2.719l.07.4c-2.99.474-9.384 1.487-17.203 2.718z"
            ></path>
            <path
                fill="#131313"
                d="M85.319 76.316c-3.683 0-9.686-5.559-13.922-9.786-1.067-1.067-2.06-2.089-2.86-2.91l-.746-.766c-.085-.086-.165-.17-.245-.248-1.878-1.91-4.528-2.484-7.09-3.042-2.638-.574-5.132-1.115-6.467-3.08l.359-.218c1.24 1.827 3.652 2.35 6.205 2.905 2.624.569 5.34 1.159 7.306 3.158l.248.25.749.768c.797.82 1.79 1.84 2.855 2.908 2.462 2.46 9.95 9.943 13.838 9.651.151-.01.293-.032.424-.067.928-.232 1.95-.644 3.035-1.22 3.76-1.997 8.112-5.899 11.083-9.938l.35.232c-3.005 4.088-7.412 8.035-11.226 10.061-1.115.593-2.169 1.019-3.13 1.258-.16.041-.328.068-.504.079-.086.005-.171.01-.26.01l-.002-.005z"
            ></path>
            <path
                fill="#131313"
                d="M68.27 63.078l-.257-.323c2.17-1.533 4.366-2.843 5.827-3.673l.219.348c-1.452.824-3.635 2.126-5.793 3.65l.003-.002zM10.76 98.182l-.095-.092c-2.598-2.54-1.628-4.912-.597-7.426.418-1.024.853-2.082 1.064-3.198l.421.073c-.219 1.153-.66 2.23-1.087 3.27-1.022 2.493-1.901 4.646.504 6.996l.09.089-.3.285v.003zM46.996 98.128h-.427v-.17c-.012-3.187-.416-5.666-1.105-6.806l.37-.202c.726 1.199 1.15 3.75 1.162 6.998l.002.18h-.002z"
            ></path>
            <path
                fill="#131313"
                d="M44.562 91.378c-1.713 0-2.735-.994-3.806-2.04-1.187-1.155-2.41-2.352-4.764-2.414l.011-.404c2.528.065 3.871 1.377 5.06 2.536 1.247 1.218 2.326 2.268 4.515 1.8l4.141-1.82.18.367-4.204 1.84c-.41.09-.786.13-1.133.13v.005zM114.143 102l-.06-.399c1.796-.245 2.912-.867 3.316-1.854.609-1.487-.401-3.799-2.914-6.687-.544-.625-1.147-2-1.904-4.325-.96-2.95-2.033-7.068-3.171-11.427-1.355-5.193-2.758-10.563-4.25-15.16l.407-.118c1.495 4.61 2.898 9.983 4.255 15.18 1.136 4.355 2.212 8.467 3.166 11.407.728 2.241 1.326 3.61 1.824 4.184 2.656 3.05 3.66 5.435 2.983 7.09-.464 1.134-1.694 1.842-3.655 2.109h.003zM105.14 62.092c-.151-.46-.302-.91-.452-1.353l.407-.124c.15.442.301.898.452 1.358l-.407.119z"
            ></path>
            <path
                fill="#131313"
                d="M114.269 98.15h-.732c-1.711 0-3.367-.76-4.429-2.037-1.483-1.79-3.634-4.514-5.456-7.332l.364-.21c1.813 2.802 3.954 5.513 5.428 7.294.982 1.177 2.511 1.88 4.093 1.88h.732v.405z"
            ></path>
            <path
                fill="#131313"
                d="M112.794 88.878h-8.96v-.404h8.949l.011.404zM96.579 52.723v-.404c4.252-.01 7.713-3.298 7.713-7.326 0-4.029-3.47-7.324-7.736-7.324-4.267 0-7.736 3.285-7.736 7.324s3.43 7.28 7.645 7.323l-.006.404c-4.449-.045-8.066-3.513-8.066-7.727 0-4.215 3.663-7.728 8.163-7.728s8.163 3.468 8.163 7.728-3.652 7.72-8.14 7.73zM20.172 86.37a76.234 76.234 0 01-4.569-14.295l.419-.08a75.71 75.71 0 004.546 14.218l-.396.154v.002zM89.65 56.172H69.056v.404H89.65v-.404zM0 97.96V74.585h.427v23.363L0 97.961z"
            ></path>
            <path
                fill="#131313"
                d="M85.495 98.15v-.405c8.906 0 14.844-2.996 18.148-9.16.9-1.68 1.605-3.62 2.098-5.77.407-1.762.677-3.729.805-5.844.342-5.556-.139-10.684-1.389-14.825l-.017-.057a25.682 25.682 0 00-.449-1.344c-.601-1.641-1.324-3.075-2.155-4.258-1.696-2.425-3.76-3.726-5.963-3.761-.046-.003-.08 0-.114-.003-2.22.006-4.52 1.312-6.646 3.78-1.754 2.032-3.376 4.824-4.819 8.291l-.088.21-4.275-2.158c-2.041-1.029-3.794-1.915-5.317-2.68l-1.221-.612-.245-.122a177.874 177.874 0 00-4.352-2.115l-.063-.03-.031-.059a3.97 3.97 0 00-.513-.722c-.8-.908-1.972-1.428-3.221-1.428-1.25 0-2.42.52-3.225 1.423l-.066.073H14.371c0 .746.014 1.522.04 2.312.1 2.977.384 5.987.842 8.948v.03h-.222l-.202.03a76.002 76.002 0 01-.848-8.995 69.933 69.933 0 01-.04-2.528v-.202h48.243a4.745 4.745 0 013.484-1.495c1.337 0 2.67.571 3.549 1.57.196.22.378.467.532.736 1.093.51 2.425 1.16 4.3 2.091l.246.122c.39.194.797.396 1.22.611 1.524.765 3.28 1.652 5.318 2.681l3.854 1.946c1.44-3.411 3.05-6.165 4.799-8.189 2.208-2.565 4.622-3.923 6.976-3.931.045 0 .09 0 .134.003 2.337.037 4.52 1.4 6.299 3.942.848 1.212 1.591 2.675 2.2 4.349.159.433.313.891.455 1.366l.017.056c1.264 4.185 1.748 9.358 1.406 14.96-.131 2.137-.404 4.125-.817 5.91-.501 2.182-1.218 4.157-2.131 5.865-3.339 6.227-9.573 9.382-18.53 9.382v.006z"
            ></path>
        </svg>
    )
}

export { PPaySVG }
