import React from 'react'
import ReactPlayer from 'react-player'
import { Fade } from 'react-awesome-reveal'
import * as styles from './styles.module.scss'

const VideoBlocks = () => {
    return (
        <div className="py-14 lg:py-28 bg-strong space-y-10 lg:space-y-28 relative -top-[8rem]">
            <div className="custom-container flex flex-col lg:flex-row space-y-10 lg:space-x-28 items-center">
                <div className="w-full lg:w-2/4">
                    <div className={styles.player_wrapper}>
                        <ReactPlayer
                            playsinline={false}
                            background={1}
                            url={
                                'https://player.vimeo.com/video/780813054?h=36cf7eb8d4&badge=0&autopause=0&player_id=0&app_id=58479/embed'
                            }
                            controls={true}
                            width="100%"
                            height="100%"
                            className={styles.react_player}
                            loop={false}
                        />
                    </div>
                </div>
                <div className="flex w-full lg:w-2/4">
                    <Fade direction="up" triggerOnce delay={300}>
                        <p className="text-light">
                            See Souvie in action around the world at Art Basel
                            fairs, VidCon, SXSW and more.
                        </p>
                    </Fade>
                </div>
            </div>
            <div className="custom-container flex flex-col lg:flex-row-reverse space-x-reverse space-y-10 lg:space-x-reverse lg:space-x-28 items-center">
                <div className="w-full lg:w-2/4">
                    <div className={styles.player_wrapper}>
                        <ReactPlayer
                            playsinline={false}
                            background={1}
                            url={
                                'https://player.vimeo.com/video/779724428?h=4e58708cbb&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
                            }
                            controls={true}
                            width="100%"
                            height="100%"
                            className={styles.react_player}
                            loop={false}
                        />
                    </div>
                </div>
                <div className="flex w-full lg:w-2/4">
                    <Fade direction="up" triggerOnce delay={300}>
                        <p className="text-light">
                            Learn how seamless and easy Souvie makes receiving
                            your souvenir token.
                        </p>
                    </Fade>
                </div>
            </div>
        </div>
    )
}

export { VideoBlocks }
